import Vue from 'vue'
import VueRouter from 'vue-router'
// 默认首页
// const root = {
//     path: '/',
//     redirect: '/index',
//     component: () => import('@/layouts/content'),
//     title: '统一门户',
//     children: [{
//         path: '/index',
//         component: () => import('@/views/unified/index'),
//         title: '统一门户',
//     }]
// }
// 场景运营
// const scene = {
//     path: '/envelope',
//     redirect: '/envelope',
//     component: () => import('@/layouts/content'),
//     title: '场景运营',
//     children: [{
//             path: '/envelope',
//             component: () => import('@/views/portal/envelope'),
//             title: '红包雨活动'
//         },
//         {
//             path: '/program',
//             component: () => import('@/views/portal/program'),
//             title: '小程序广告',
//         },
//         {
//             path: '/program/add',
//             component: () => import('@/views/portal/programAdd'),
//         },
//         {
//             path: '/score',
//             component: () => import('@/views/portal/score'),
//             title: '评分页广告',
//         },
//         {
//             path: '/score/scoreAdd',
//             component: () => import('@/views/portal/scoreAdd')

//         },
//         {
//             path: '/k-gold',
//             component: () => import('@/views/k-gold/k-gold'),
//             title: '商家配置',
//         },
//         {
//             path: '/k-gold-trace',
//             component: () => import('@/views/k-gold/k-gold-trace'),
//             title: '平台配置',
//         }
//     ]
// }



//服务通
const service = {
    path: '/',
    redirect: '/brandManage',
    component: () => import('@/layouts/content'),
    title:'服务通',
    children: [
        {
            path: '/brandManage',
            component: () => import('@/views/service/brandManage/brandManage'),
            title:'品牌管理',
        },
        {
            path: '/staffManage',
            component: () => import('@/views/service/staffManage/staffManage'),
            title:'员工管理',
        },
        {
            path: '/stationManage',
            component: () => import('@/views/service/stationManage/stationManage'),
            title:'岗位管理',
        },
        {
            path: '/areaManage',
            component: () => import('@/views/service/areaManage/areaManage'),
            title:'区域管理',
        },
        {
            path: '/serviceArea',
            component: () => import('@/views/service/serviceArea/serviceArea'),
            title:'服务区域',
        },
        {
            path: '/PassiveTask',
            component: () => import('@/views/service/PassiveTask/PassiveTask'),
            title:'被动任务管理',
        },
        {
            path: '/PlanningTask',
            component: () => import('@/views/service/PlanningTask/PlanningTask'),
            title:'计划任务管理',
        },
        
    ]   
}
// 登陆
const signin = {
    path: "/login",
    name: "login",
    component: resolve => {
        require(["../views/login/login"], resolve);
    },
    meta: {
        title: "登录"
    }
};
// 返回的路由
const routes = [
    // root,
    // scene,
    signin,
    service
]
Vue.use(VueRouter)
const router = new VueRouter({
    routes
})

export default router