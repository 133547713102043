import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import elemenet from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import util from './utils'
import globalConfig from './config/global';
window.globalConfig = globalConfig;
Vue.use(util);
require('./assets/App.css')
Vue.config.productionTip = false
Vue.use(elemenet)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
